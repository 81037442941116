export const getTracker = () => {
  const data = JSON.parse(localStorage.getItem("tracker"));
  return data;
};

export const putTracker = async (data) => {
  data.user_agent = window.navigator.userAgent;
  localStorage.setItem("tracker", JSON.stringify(data));
};

export const DataTracker = (
  utm_source = "",
  utm_campaign = "",
  utm_medium = "",
  utm_content = "",
  user_agent = "",
  gclid = "",
  guid = ""
) => {
  return {
    utm_source,
    utm_campaign,
    utm_medium,
    utm_content,
    user_agent,
    gclid,
    guid
  };
};
